export const applyDynamicModelOverrides = async (dynamicModelPromise, publicModel, rendererModel, logger) => {
    try {
        const dynamicModel = await dynamicModelPromise
        // rendererModel overrides
        rendererModel.mediaAuthToken = dynamicModel.mediaAuthToken
        Object.values(dynamicModel.apps).forEach(app => {
            const appId = app.intId
            rendererModel.clientSpecMap[appId].instance = app.instance
        })
        // publicModel overrides
        const {hs, ctToken, visitorId, svSession} = dynamicModel
        Object.assign(publicModel.sessionInfo, {hs, ctToken, visitorId, svSession})
    } catch (e) {
        logger.captureError(e)
    }
}
