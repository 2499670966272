import {getWorkerWrapperIframeUrlOverride, getWorkerUrlOverride} from './createLocalWorker'

const addIFrameToDOM = iframe => {
    if (window.document.readyState !== 'loading') {
        prependIframeToBody()
    } else {
        window.document.addEventListener('DOMContentLoaded', () => {
            prependIframeToBody()
        })
    }

    function prependIframeToBody() {
        const body = window.document.body
        body.insertBefore(iframe, body.firstChild)
    }
}

window.iframeMessages = []

module.exports = (isDebug, isInSSR, boltBase) => {
    const _ = require('lodash')
    const state = {
        iframe: null,
        iframeUrl: null,
        isIframeReady: false,
        queuedMessages: [],
        bufferedMessagesByContextId: {},
        eventListenerByContextId: {}
    }

    const removeIFrameFromDOM = () => {
        window.document.body.removeChild(state.iframe)

        state.iframeUrl = null
        state.isIframeReady = false
        state.queuedMessages = []
    }

    const isIFrameEvent = message => state.iframe ? message.source === state.iframe.contentWindow : false

    const handleIFrameMessages = message => {
        if (!isIFrameEvent(message)) {
            return
        }

        if (message.data.type === 'IFRAME_LOADED') {
            state.isIframeReady = true
            state.queuedMessages.forEach(obj => obj.postMessage(obj.message, obj.transfer))
            state.queuedMessages = []
            return
        }
        if (isDebug) {
            const copy = JSON.parse(JSON.stringify(message.data))

            window.iframeMessages = window.iframeMessages || []
            window.iframeMessages.push({direction: 'INCOMING', ...copy, timestamp: performance.now()})
        }

        const contextId = _.get(message, 'data.__messageContextId')
        const eventListener = _.get(state.eventListenerByContextId, contextId)
        if (!eventListener) {
            let bufferedMessages = _.get(state.bufferedMessagesByContextId, contextId)

            if (_.isNil(bufferedMessages)) {
                bufferedMessages = []
                _.set(state.bufferedMessagesByContextId, contextId, bufferedMessages)
            }

            bufferedMessages.push(message)
        } else {
            _.invoke(state.eventListenerByContextId, contextId, message)
        }
    }

    window.addEventListener('message', handleIFrameMessages, false)

    const createIframe = (url, workerUrl) => {
        if (url === state.iframeUrl) {
            return
        } else if (state.iframe) {
            removeIFrameFromDOM()
        }

        state.iframeUrl = url
        state.iframe = window.document.createElement('iframe')
        // The iframe is intentially visible since it may improve cpu/network priority for it
        state.iframe.style.cssText = 'position: fixed; left: 0; right: 0; top: 0; bottom: 0; width: 1px; height: 1px; background: transparent; border: 0'
        state.iframe.tabIndex = -1
        state.iframe.setAttribute('aria-hidden', 'true')
        const iframeUrlOverride = getWorkerWrapperIframeUrlOverride(isInSSR, boltBase)
        const workerUrlOverride = getWorkerUrlOverride(isInSSR, boltBase)

        if (iframeUrlOverride && workerUrlOverride) {
            url = iframeUrlOverride
            workerUrl = workerUrlOverride
        }

        state.iframe.src = `${url}?workerUrl=${workerUrl}&isDebug=${isDebug}`

        addIFrameToDOM(state.iframe)
    }

    return async (url, workerUrl, setIframeWorkerWrapper, contextId) => {
        if (!contextId || !url) {
            return
        }

        const init = () => {
            const postMessage = (message, transfer) => {
                if (message) {
                    if (state.isIframeReady) {
                        state.iframe.contentWindow.postMessage({...message, __messageContextId: contextId}, '*', transfer)
                        if (isDebug) {
                            const clone = JSON.parse(JSON.stringify(message))

                            window.iframeMessages = window.iframeMessages || []
                            window.iframeMessages.push({direction: 'OUTGOING', ...clone, arg1: transfer, timestamp: performance.now(), contextId})
                        }
                    } else {
                        state.queuedMessages.push({message, postMessage, transfer})
                    }
                }
            }

            const terminate = () => {
                postMessage({type: 'TERMINATE'})
                delete state.eventListenerByContextId[contextId]
            }

            const addEventListener = (type, handler) => {
                if (type !== 'message') {
                    throw new Error('cannot add event listener to message type which is not message')
                }

                state.eventListenerByContextId[contextId] = handler

                const bufferedMessages = _.get(state.bufferedMessagesByContextId, contextId)

                if (!_.isNil(bufferedMessages)) {
                    bufferedMessages.forEach(message => {
                        _.invoke(state.eventListenerByContextId, contextId, message)
                    })

                    delete state.bufferedMessagesByContextId[contextId]
                }
            }

            createIframe(url, workerUrl)
            postMessage({type: 'INIT'})

            setIframeWorkerWrapper({
                postMessage,
                terminate,
                addEventListener
            })
        }

        init(url)
    }
}
